import { ReactNode, useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import Logo from 'assets/logo/logo.svg';

const SHOW_MODAL = false;

const WELCOME_MODAL = 'welcomeModal';
const ACCEPT_COOKIES = 'acceptCookies';

type Props = {
  children: ReactNode;
};

const App = ({ children }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [cookies, setCookie] = useCookies([WELCOME_MODAL, ACCEPT_COOKIES]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCookiesBarVisible, setIsCookiesBarVisible] = useState(false);

  const handleCookiesBarClose = useCallback(() => {
    setCookie(ACCEPT_COOKIES, true, { path: '/', maxAge: 365 * 24 * 60 * 60 });

    setIsCookiesBarVisible(false);
  }, [setCookie]);

  const handleCookiesBarShow = useCallback(() => setIsCookiesBarVisible(true), []);

  useEffect(() => {
    if (!cookies[ACCEPT_COOKIES]) {
      handleCookiesBarShow();
    }
  }, [handleCookiesBarShow, cookies]);

  const handleModalClose = useCallback(() => {
    setCookie(WELCOME_MODAL, true, { path: '/', maxAge: 1 * 24 * 60 * 60 });

    setIsModalVisible(false);
  }, [setCookie]);

  const handleModalShow = useCallback(() => setIsModalVisible(true), []);

  useEffect(() => {
    if (!cookies[WELCOME_MODAL] && SHOW_MODAL) {
      handleModalShow();
    }
  }, [handleModalShow, cookies]);

  return (
    <>
      {children}

      <Modal
        show={isModalVisible}
        onHide={handleModalClose}
        centered
        scrollable
        className="welcome-modal"
      >
        <Modal.Header closeButton>
          <img src={Logo} alt="Praymaker" />
        </Modal.Header>
        <Modal.Body>
          Pariatur nulla irure fugiat elit laborum tempor labore cillum in mollit. Ad magna fugiat
          culpa velit tempor pariatur in duis eiusmod mollit anim cillum incididunt. Nulla eiusmod
          Lorem aliquip aute exercitation ut nostrud est officia nostrud aliquip velit sit Lorem.
          Sit ad duis culpa incididunt irure officia culpa aliqua nisi est est cillum sunt minim.
          Magna irure ea dolore do et incididunt tempor nostrud quis voluptate est.
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={handleModalClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Alert show={isCookiesBarVisible} onClose={handleCookiesBarClose} className="cookie-bar">
        <div className="container">
          <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center">
            <span>
              We use cookies to personalise content, and to analyse our traffic.{' '}
              <Link to="/privacy-policy">Learn more</Link>
            </span>
            <button
              type="button"
              className="btn btn-primary accept-cookies-btn"
              onClick={handleCookiesBarClose}
            >
              Accept Cookies
            </button>
          </div>
        </div>
      </Alert>
    </>
  );
};

export default App;
