import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import './styles/main.scss';

import App from './App';

import ApplicationForm from './screens/ApplicationForm';
import Index from './screens/Index';
import Participate from './screens/Participate';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ReferralForm from './screens/ReferralForm';
import RequestAccepted from './screens/RequestAccepted';
import RequestForm from './screens/RequestForm';
import RequestRejected from './screens/RequestRejected';
import TermsOfUse from './screens/TermsOfUse';

render(
  <CookiesProvider>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/">
            <Route index element={<Index />} />
            <Route path="participate" element={<Participate />} />
            <Route path="application-form" element={<ApplicationForm />} />
            <Route path="request-form" element={<RequestForm />} />
            <Route path="referral-form" element={<ReferralForm />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="request-accepted" element={<RequestAccepted />} />
            <Route path="request-rejected" element={<RequestRejected />} />
          </Route>
        </Routes>
      </App>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root')
);
