import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Footer from 'components/Footer';
import Logo from 'assets/logo/logo.svg';

const RequestAccepted = () => {
  useEffect(() => {
    document.title = 'Praymaker • Request accepted successfully';
  }, []);

  return (
    <>
      <header className="page-section-header simple">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Praymaker" />
            </Link>
          </div>
        </div>
      </header>

      <section className="page-section-main">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h3>You have successfully accepted the prayer request. Thank you!</h3>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default RequestAccepted;
