import { Link } from 'react-router-dom';

const CtaSmall = () => (
  <section className="page-section-cta-small">
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col col-md-9">
          <h2>Are you a spiritual leader?</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col col-lg-6 col-md-9">
          <p>
            The Praymaker family is committed to connecting your community in faith, hope, and
            support.
          </p>
          <Link
            to="/participate"
            className="btn btn-primary-inverted btn-lg block-xs"
            role="button"
          >
            Become a Praymaker
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default CtaSmall;
