import FacebookIcon from 'assets/social/facebook.svg';
import InstagramIcon from 'assets/social/instagram.svg';
import TwitterIcon from 'assets/social/twitter.svg';

type Props = {
  className: string;
};

const SocialIcons = ({ className }: Props) => (
  <div className={`social-icons ${className}`}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a href="" target="_blank" rel="noopener noreferrer">
      <img src={FacebookIcon} alt="Praymaker on Facebook" />
    </a>
    <a href="https://www.instagram.com/praymaker_" target="_blank" rel="noopener noreferrer">
      <img src={InstagramIcon} alt="Praymaker on Instagram" />
    </a>
    <a href="https://twitter.com/praymaker2" target="_blank" rel="noopener noreferrer">
      <img src={TwitterIcon} alt="Praymaker on Twitter" />
    </a>
  </div>
);

export default SocialIcons;
