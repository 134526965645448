import { Link } from 'react-router-dom';

import SocialIcons from './SocialIcons';
import LogoNegative from 'assets/logo/logo_negative.png';

const Footer = () => (
  <section className="page-section-footer">
    <div className="container">
      <div className="row">
        <div className="col col-lg-10 offset-lg-1">
          <h3>More Resources</h3>
          <p>
            <strong>The National Suicide Prevention Hotline</strong> provides 24/7, free and
            confidential support for people in distress, prevention and crisis resources for you
            oryour loved ones, and best practices for professionals. Please call{' '}
            <a href="tel:1-800-273-8255" className="text-nowrap">
              1-800-273-8255
            </a>{' '}
            for help.
          </p>
          <p>
            <strong>The National Alliance on Mental Illness</strong> provides advocacy, education,
            support and public awareness so that all individuals and families affected by mental
            illness can build better lives.
            <br />
            <a
              href="https://www.nami.org/Support-Education"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.nami.org/Support-Education
            </a>
          </p>
        </div>
      </div>
    </div>

    <hr />

    <div className="container">
      <div className="row">
        <div className="col col-lg-10 offset-lg-1">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/">
                <img src={LogoNegative} alt="Praymaker" />
              </Link>
            </div>
            <SocialIcons className="d-none d-sm-block" />
          </div>
          <div className="spacing-small" />
          <div className="d-flex justify-content-between align-items-end footer-menu">
            <div>
              <h4>Contact Us</h4>
              <a href="mailto:info@praymaker.com">info@praymaker.com</a>
            </div>
            <SocialIcons className="d-block d-sm-none" />
            <div>
              <div>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div>
                <Link to="/terms-of-use">Terms Of Use</Link>
              </div>
            </div>
          </div>
          <div className="copyright">© Copyrights {new Date().getFullYear()} | Praymaker Inc.</div>
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
