import { useEffect } from 'react';
import { Widget } from '@typeform/embed-react';

const ApplicationForm = () => {
  useEffect(() => {
    document.title = 'Praymaker • Become a Praymaker';
  }, []);

  return (
    <Widget
      style={{ height: '100vh' }}
      id={process.env.REACT_APP_TYPEFORM_PRIEST_APPLICATION_FORM_ID as string}
    />
  );
};

export default ApplicationForm;
