import { Link } from 'react-router-dom';

type Props = {
  bgClass: string;
  btnLink: string;
  btnText: string;
  text: string;
};

const CtaLarge = ({ bgClass, btnLink, btnText, text }: Props) => (
  <section className={`page-section-cta-large ${bgClass}`}>
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col col-lg-11 col-xl-9">
          <div className="display-1 dot">{text}</div>
          <Link
            to={btnLink}
            className="btn btn-primary btn-hover-no-border btn-lg block-xs"
            role="button"
          >
            {btnText}
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default CtaLarge;
