import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import CtaLarge from 'components/CtaLarge';
import CtaSmall from 'components/CtaSmall';
import Footer from 'components/Footer';
import Logo from 'assets/logo/logo.svg';
import Line from 'assets/steps/line.svg';
import Step1 from 'assets/steps/step_1.svg';
import Step2 from 'assets/steps/step_2.svg';
import Step3 from 'assets/steps/step_3.svg';

const Index = () => {
  useEffect(() => {
    document.title = 'Praymaker • Welcome';
  }, []);

  return (
    <>
      <Header
        bgClass="clouds-bg"
        logo={Logo}
        btnTopLink="/participate"
        btnTopClass="btn-outline-primary"
        btnTopText="Are you a spiritual leader?"
        heading="Personalized Prayer Delivered"
        btnLink="/request-form"
        btnText="Request a Prayer"
      >
        <div className="col col-lg-11">
          <p className="lead">
            Our lives are filled with celebration, sorrow, and hope, and in these moments, the right
            words are sometimes hard to find.{' '}
            <strong>
              Praymaker connects you with a spiritual leader to create a personalized prayer texted
              directly to your loved one as an audio recording.
            </strong>
          </p>
        </div>
      </Header>

      <section className="page-section-main">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col col-lg-9">
              <h2 className="dot">Trust us to find the right words</h2>
              <p>
                Praymaker allows you to request a personal prayer that is created by a religious
                leader of your or your recepient's faith. Whether it is a prayer for celebration or
                condolence, for you or a friend,{' '}
                <strong>Praymaker helps you individualize the request.</strong> For less than the
                cost of flowers and a greeting card, you get the right words delivered.
              </p>
            </div>
          </div>
          <div className="spacing-medium" />
          <h2>How it works?</h2>
          <div className="steps">
            <div className="row justify-content-around step-numbers d-none d-md-flex">
              <div className="line">
                <img src={Line} alt="" />
              </div>
              <div className="col-md-4 col-lg-3 align-self-end">
                <div className="step-number step-number-1">1</div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="step-number step-number-2">2</div>
              </div>
              <div className="col-md-4 col-lg-3 align-self-end">
                <div className="step-number step-number-3">3</div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-md-4 col-lg-3">
                <div className="step-number d-block d-md-none">1</div>
                <img src={Step1} className="step-icon step-icon-1" alt="Step 1" />
                <p>
                  <strong>Request a prayer for any intention</strong>
                </p>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="step-number d-block d-md-none">2</div>
                <img src={Step2} className="step-icon step-icon-2" alt="Step 2" />
                <p>
                  <strong>Spiritual leader creates a personalized prayer</strong>
                </p>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="step-number d-block d-md-none">3</div>
                <img src={Step3} className="step-icon step-icon-3" alt="Step 3" />
                <p>
                  <strong>The audio prayer is texted to your loved one</strong>
                </p>
              </div>
            </div>
          </div>
          <Link to="/request-form" className="btn btn-primary btn-lg block-xs" role="button">
            Request a Prayer
          </Link>
          <div className="spacing-large" />
          <div className="row justify-content-center">
            <div className="col col-lg-9">
              <h3>Turnaround Time</h3>
              <p>
                The turnaround time depends on whether you chose a religious leader from our
                Praymaker team or you designate your own. If you choose one from Praymaker, we do
                our best to turn it around in <strong>3-5 days</strong>. We also know that sometimes
                you don’t have 3-5 days, so the Praymaker team does their best to prioritize. In
                some cases, we can deliver the prayer at the time or day you indicate.
              </p>
              <p>
                If you choose your own religious leader, we ask for <strong>7–10 days</strong>{' '}
                because we have to personally reach out to them, explain our platform, and provide
                them time to create a thoughtful, intentional prayer.
              </p>
            </div>
          </div>
          <div className="spacing-small" />
          <div className="row justify-content-center">
            <div className="col col-lg-9">
              <h3>Our Religious Leaders</h3>
              <p>
                Our religious leaders are vetted by the Praymaker Team. We have a diverse list of
                people who share the common values of respect, generosity, humility, and community
                of all faiths. There is no place for bigotry or hate at Praymaker.
              </p>
              <p>
                If you have specific questions or would like to request we add a religious leader,
                please reach out to us at <a href="mailto:info@praymaker.com">info@praymaker.com</a>
                .
              </p>
            </div>
          </div>
          <Link to="/referral-form" className="btn btn-primary btn-lg block-xs" role="button">
            Refer a Religious Leader
          </Link>
        </div>
      </section>

      <CtaLarge
        bgClass="candles-bg"
        btnLink="/request-form"
        btnText="Request a Prayer"
        text="Personal prayer for your loved ones"
      />

      <CtaSmall />

      <Footer />
    </>
  );
};

export default Index;
