import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import CtaLarge from 'components/CtaLarge';
import Footer from 'components/Footer';
import Logo from 'assets/logo/logo.svg';
import LogoNegative from 'assets/logo/logo_negative.png';
import Megaphone from 'assets/icons/megaphone.svg';
import Charity from 'assets/icons/charity.svg';

const Participate = () => {
  useEffect(() => {
    document.title = 'Praymaker • Become a Praymaker';
  }, []);

  return (
    <>
      <Header
        bgClass="sky-bg"
        logo={LogoNegative}
        logoMenuOpen={Logo}
        btnTopLink="/request-form"
        btnTopClass="btn-transparent"
        btnTopText="Request a Prayer"
        heading="Become a Praymaker"
        btnLink="/application-form"
        btnText="Become a Praymaker"
      >
        <div className="col col-lg-8">
          <p className="lead">
            The Praymaker family is committed to connecting your community in faith, hope, and
            support. Becoming a Praymaker helps you spread your spiritual message around the world.
          </p>
        </div>
      </Header>

      <section className="page-section-main">
        <div className="container text-center-md">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h2>Why Praymaker</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <p>
                When going through life’s heartaches, challenges, or unknowns, one of the most
                frequent things friends and family say is, <strong>“I’ll say a prayer.”</strong>{' '}
                When you are celebrating something joyous or anticipating a big life change, people
                say, <strong>“I pray it goes smoothly.”</strong> We wanted to create the ability for
                people to translate those statements into something tangible, something actionable.
              </p>
              <p>
                Praying is hard for any number of reasons: we don’t know what to say, we don’t know
                how to do it, we have been out of the game for a while.{' '}
                <strong>Praymaker makes it easy.</strong>
              </p>
            </div>
          </div>
          <div className="spacing-small" />
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h2>Benefits of participation</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4 offset-lg-1">
              <img src={Megaphone} alt="Benefit 1" className="benefits-icon" />
              <h3>Spread Your Message</h3>
              <p>
                We appreciate your talent, experience and credibility. Coming up with the right
                words is not easy, and now more then ever people need you.
              </p>
              <p>
                Praymaker increases access to your faith community. People from across the world
                could learn about you, your congregation, and your mission. It is a great tool to
                connect with people who are homebound or in some other way distanced from their
                faith community.
              </p>
            </div>
            <div className="col-12 col-lg-4 offset-lg-1">
              <img src={Charity} alt="Benefit 2" className="benefits-icon" />
              <h3>Honorarium</h3>
              <p>
                A large percentage of every dollar collected for your prayer and time goes directly
                back to your congregation.
              </p>
              <p>
                We understand that bringing in revenue is never the first priority of a religious
                community, but we also know that it takes money to keep the lights on.
              </p>
              <p>
                We have also created the option for the customer to tithe additional money beyond
                the administrative fees to create the prayer.
              </p>
            </div>
          </div>
          <div className="spacing-small" />
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h2>How to participate?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <p>
                Our religious leaders are vetted by the Praymaker team. We seek to have a diverse
                list of people who share the common values of respect, generosity, humility, and
                community of all faiths.
              </p>
              <p>
                Please complete a simple <Link to="/application-form">registration form</Link> and
                someone from the Praymaker team will contact you to set up an account, complete the
                appropriate tax documents, and answer any questions.
              </p>
              <div className="spacing-small" />
            </div>
          </div>
        </div>
      </section>

      <CtaLarge
        bgClass="tree-bg"
        btnLink="/application-form"
        btnText="Become a Praymaker"
        text="Start spreading your spiritual message"
      />

      <Footer />
    </>
  );
};

export default Participate;
