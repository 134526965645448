import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import CtaLarge from 'components/CtaLarge';
import CtaSmall from 'components/CtaSmall';
import Footer from 'components/Footer';
import Logo from 'assets/logo/logo.svg';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Praymaker • Privacy Policy';
  }, []);

  return (
    <>
      <header className="page-section-header simple">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Praymaker" />
            </Link>
          </div>
        </div>
      </header>

      <section className="page-section-main">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h1>Privacy Policy</h1>

              <p className="spacing-small">Last updated: 4/8/2022</p>

              <h2>1) Overview</h2>

              <p>
                Leigh Kim Consulting, LLC d/b/a Praymaker (“Praymaker”) provides service to you
                subject to this Privacy Policy which may be updated by Praymaker from time to time.
                Praymaker knows you care about your privacy and the use of your personal
                information. This Privacy Policy explains how Praymaker collects, stores, uses, and
                discloses your personal information.
              </p>
              <p>Praymaker provides services to you through:</p>
              <ul>
                <li>
                  (i) the <a href="https://www.praymaker.com">www.praymaker.com</a> website to, for
                  example, send, receive, or create Prayers (defined below);
                </li>
                <li>(ii) social media profiles owned or controlled by Praymaker;</li>
                <li>(iii) sending or receiving communications to or from you; and</li>
                <li>(iv) otherwise interacting with you (collectively, the “Services”).</li>
              </ul>
              <p>
                The Services provide a platform whereby personalized prayer requests may be made by
                requestors (“Prayer Requests” and “Requestors”) and personalized prayers (“Prayers”)
                may be provided in response by spiritual advisors (“Advisors”) either directly to
                Requestors or to third party recipients designated by Requestors (“Recipients”).
                Praymaker’s Terms of Use and this Privacy Policy apply to all users of the Services,
                including Requestors, Advisors, and Recipients.
              </p>

              <h2>2) Scope of Privacy Policy</h2>

              <p>This Privacy Policy describes:</p>
              <ul>
                <li>Information that we receive from you when you interact with the Services;</li>
                <li>How we use and process the information that we receive;</li>
                <li>If and why Personal Information may be disclosed to third parties; and</li>
                <li>
                  Your choices regarding the collection and processing of your Personal Information.
                </li>
              </ul>
              <p>
                Please note that this Privacy Policy does not apply to information collected by our
                third party service providers you may interact with when accessing the Services,
                such as payment provider services, or that you submit to us through e-mail, text
                message, social media, any other electronic message, or offline.
              </p>
              <p>
                <strong>PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY.</strong>
              </p>
              <p>
                By using the Services, you accept the terms of this Privacy Policy and our Terms of
                Use and consent to our collection, use, disclosure and retention of your information
                as described in this Privacy Policy.
              </p>
              <p>
                If you have not done so already, please review our Terms of Use. The Terms of Use
                contain provisions that limit our liability to you and require you to:
              </p>
              <ul>
                <li>(i) waive your right to bring a lawsuit in court;</li>
                <li>(ii) submit any dispute with us to artbitration;</li>
                <li>
                  (iii) agree to bring disputes in the State of Illinois and pursuant to the law of
                  the State of Illinois; and
                </li>
                <li>
                  (iv) resolve any dispute with us on an individual basis and not as part of any
                  class or representative action.
                </li>
              </ul>
              <p>This Privacy Policy is incorporated into and made a part of our Terms of Use.</p>
              <p>
                <strong>
                  IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE,
                  PLEASE DO NOT USE ANY OF THE SERVICES.
                </strong>
              </p>
              <p>
                The Effective Date of this Privacy Policy is set forth at the top of this Policy.
                Whenever possible, we will provide you with advance written notice of our changes to
                this Privacy Policy. Your continued use of the Services after the Effective Date
                constitutes your acceptance of the amended Privacy Policy. The amended Privacy
                Policy supersedes all previous versions.
              </p>

              <h2>3) Information We Collect</h2>

              <p>
                We collect various kinds of information that you provide to us as well as
                information we obtain from your use of the Services. The types of information that
                we collect include:
              </p>

              <h3>a. Personal Information</h3>

              <p>
                “Personal Information” is information associated with or used to identify or contact
                a specific person. Personal Information we collect includes:
              </p>
              <ul>
                <li>
                  (i) Requestor contact data (such as name, e-mail address, telephone number, and
                  religion);{' '}
                </li>
                <li>
                  (ii) Prayer data (such as reason for and description of the Prayer, religion,
                  Requestor relationship with Recipient, and Advisor Prayer recording);
                </li>
                <li>
                  (iii) Recipient data (such as Recipient name, including phonetic spelling, e-mail
                  address, telephone number, and language); and
                </li>
                <li>
                  (iv) Advisor data (such as name, e-mail address, telephone number, religion,
                  ordained title, Prayer preferences, languages known, and payment information); and
                </li>
                <li>
                  (v) Payment data (such as last 4 digits of credit card number, name on credit
                  card, and billing ZIP code).
                </li>
              </ul>

              <h2>4) How We Collect Information</h2>

              <p>
                Whether we collect certain types of information and how we process it depends on how
                you use and access the Services. Some information is collected automatically through
                use of cookies and similar data collection tools. We collect information about you
                in the following ways.
              </p>

              <h3>a. From You</h3>

              <p>
                We collect Personal Information from you when you use one of the Services, including
                by making or fulfilling a Prayer request, contacting us for help or information or
                otherwise voluntarily providing your Personal Information.
              </p>

              <h3>b. From Our Business Partners and Service Providers</h3>

              <p>
                We receive Personal Data from third party service providers that help us provide our
                services, such as data from our Prayer Request form, our electronic communication
                services provider, payment processing provider, and voice recording service.
              </p>

              <h3>c. From Cookies</h3>

              <p>
                We, along with some of the service providers that help us provide the Services, use
                small text files called “cookies,” which are computer files sent to or accessed from
                your web browser or your computer’s, mobile device’s, or tablet’s hard drive that
                contain information, such as Prayer request information, operating system type,
                browser type, domain and other system settings, as well as the operating system used
                and the country and time zone in which the computer or device is located. Cookies
                are not themselves personally identifiable, but may be linked to Personal
                Information that you provide to us through your interaction with the Services. A
                cookie typically contains the name of the domain (internet location) from which the
                cookie originated, the “lifetime” of the cookie (i.e., when it expires) and a
                randomly generated unique number or similar identifier.
              </p>
              <p>
                Cookies help us improve the Services by storing information provided when filling
                our our Prayer request form and permit our third party service providers to improve
                the Services.
              </p>
              <p>
                Web browsers allow some control of most cookies through the browser settings, such
                as permittng a user to reject cookies or alerting a user when a cookie is placed on
                the user’s computer, tablet or mobile device. Most mobile devices also offer
                settings to reject mobile device identifiers. Although users are not required to
                accept cookies or mobile device identifiers, blocking or rejecting them may prevent
                access to some features available through the Services.
              </p>
              <p>
                Some web browsers may be configured to send a “Do Not Track” signal to the sites
                that you visit. There is no consensus as to what the “Do Not Track” signal means.
                Praymaker, like many other sites, does not alter its practices when you send us a
                “Do Not Track” signal from your browser except as specifically required by law.
              </p>

              <h2>5) How We Use Your Information</h2>

              <p>We may use the information we collect for any of the following purposes:</p>
              <ul>
                <li>to provide the Services to you;</li>
                <li>
                  to operate, improve, and personalize the products and services we offer, and to
                  give each user a more consistent and personalized experience when interacting with
                  us;
                </li>
                <li>
                  for customer service, security, to detect fraud or illegal activities, or for
                  archival and backup purposes in connection with the provision of the Services;
                </li>
                <li>to communicate with users by e-mail or text message;</li>
                <li>
                  to help us develop our new products and services and improve our existing products
                  and services;
                </li>
                <li>to enforce our Terms of Use or other applicable policies; </li>
                <li>to use any Prayer consistent with our Terms of Use; and</li>
                <li>
                  to assess the effectiveness of and improve advertising and other marketing and
                  promotional activities on or in connection with the Services.
                </li>
              </ul>
              <p>
                Please note that, in order to provide you with a better experience and to improve
                the Services, information collected through the Services may be used in an
                aggregated or individualized manner.
              </p>

              <h2>6) How We Share and Disclose Your Information</h2>

              <p>
                We may share and disclose information as described at the time information is
                collected or as follows:
              </p>

              <h3>a. To perform services</h3>

              <p>
                We disclose Personal Information relating to Prayer requests to Advisors and, if
                applicable, to Recipients in order to fulfill Prayer Requests.
              </p>

              <h3>b. With third party service providers performing services on our behalf. </h3>

              <p>
                We share Personal Data with third party service providers necessary to provide our
                services, such as providing our Prayer Request form, website hosting services,
                communication services, payment processing services performed by Stripe, and voice
                recording. These service providers only use Personal Data as necessary to perform
                and, in some cases, improve those services on our behalf and do not share Personal
                Data.
              </p>
              <p>
                Some of our third party service providers collect usage data, which is information
                about your online activity that, by itself, does not identify you individual, such
                as technical information, including your browser type, IP address, service provider,
                and operating system.
              </p>

              <h3>c. For legal purposes</h3>

              <p>
                We also may share Personal Information to enforce our rights, protect our property
                or protect the rights, property or safety of others, or as needed to support
                external auditing, compliance and corporate governance functions. We will disclose
                Personal Information as we deem necessary to respond to a subpoena, regulation,
                binding order of a data protection agency, legal process, governmental request or
                other legal or regulatory process. We may also share Personal Information as
                required to pursue available remedies or limit damages we may sustain.
              </p>

              <h3>d. During corporate changes</h3>

              <p>
                We may transfer information, including your Personal Information, in connection with
                a merger, sale, acquisition or other change of ownership or control by or of us or
                any affiliated company (in each case whether in whole or in part). When one of these
                events occurs, we will use reasonable efforts to notify users before your
                information is transferred or becomes subject to a different privacy policy.
              </p>

              <h2>7) Information Storage and Security</h2>

              <p>
                We retain information as long as it is necessary and relevant for our operations.
                This includes the possibility that we will retain your data indefinitely. We also
                retain Personal Information to comply with applicable law, prevent fraud, resolve
                disputes, troubleshoot problems, assist with any investigation, enforce our Terms of
                Use, collect any fees owed and other actions permitted by law. We delete data at our
                discretion, typically in accord with our data retention and destruction policies.
              </p>
              <p>
                We, and our third party service providers, employ industry-standard security
                measures designed to protect the security of all information submitted through the
                Services. However, the security of information transmitted through the internet can
                never be guaranteed. Accordingly, and despite our reasonable efforts to protect your
                Personal Information from unauthorized access, use, or disclosure, we cannot
                guarantee or warrant the security of the Personal Information you transmit to us, or
                to or from the Services. We are not responsible for any interception or interruption
                of any communications through the internet or for changes to or losses of data.
              </p>

              <h2>8) Your Rights</h2>

              <p>It is important to us that you are able to:</p>
              <ul>
                <li>(i) access and review the Personal Information we have about you;</li>
                <li>(ii) make corrections to it or delete it; or</li>
                <li>(iii) opt out of our use of your Personal Information.</li>
              </ul>
              <p>
                To do so, please contact us at Praymaker, c/o Mercer Oak LLC, 29 N. Ada St.,
                Chicago, IL 60607 or at <a href="mailto:info@praymaker.com">info@praymaker.com</a>.
                We will make commercially reasonable efforts to meet your request(s) within 30 days.
                If you or we correct or delete your Personal Information, copies may remain viewable
                in cached and archived pages, or may have been copied or stored by our third party
                service providers, and complete removal of all traces of personal information is not
                guaranteed.
              </p>
              <p>
                To stop receiving promotional e-mails from us, click “unsubscribe” in the
                promotional email.
              </p>

              <h2>9) Disclaimer: Not Child-Directed</h2>

              <p>
                The Services are not intended for use by children. If you are under the age of
                majority in your place of residence, you may not use the Services.
              </p>
              <p>
                Consistent with the requirements of the Children’s Online Privacy Protection Act, if
                we learn that we have received any information directly from a child under age 13,
                we will use that information only to respond directly to that child (or his or her
                parent or legal guardian) to inform the child that he or she cannot use the Services
                and subsequently we will delete that information.
              </p>
            </div>
          </div>
        </div>
      </section>

      <CtaLarge
        bgClass="candles-bg"
        btnLink="/request-form"
        btnText="Request a Prayer"
        text="Personal prayer for your loved ones"
      />

      <CtaSmall />

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
