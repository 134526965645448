import { useEffect } from 'react';
import { Widget } from '@typeform/embed-react';

const RequestForm = () => {
  useEffect(() => {
    document.title = 'Praymaker • Request Prayer';
  }, []);

  return (
    <Widget
      style={{ height: '100vh' }}
      id={process.env.REACT_APP_TYPEFORM_REQUEST_FORM_ID as string}
    />
  );
};

export default RequestForm;
