import { useEffect } from 'react';
import { Widget } from '@typeform/embed-react';

const ReferralForm = () => {
  useEffect(() => {
    document.title = 'Praymaker • Refer a Religious Leader';
  }, []);

  return <Widget style={{ height: '100vh' }} id="r8z0Qm" />;
};

export default ReferralForm;
