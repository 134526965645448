import { ReactNode, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import HamburgerIcon from 'assets/menu/hamburger-icon.svg';
import CloseIcon from 'assets/menu/close-icon.svg';

type Props = {
  bgClass: string;
  logo: string;
  logoMenuOpen?: string;
  btnTopLink: string;
  btnTopClass: string;
  btnTopText: string;
  heading: string;
  btnLink: string;
  btnText: string;
  children: ReactNode;
};

const Header = ({
  bgClass,
  logo,
  logoMenuOpen,
  btnTopLink,
  btnTopClass,
  btnTopText,
  heading,
  btnLink,
  btnText,
  children,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleOpenMobileMenu = useCallback(() => setIsMobileMenuOpen(true), []);
  const handleCloseMobileMenu = useCallback(() => setIsMobileMenuOpen(false), []);

  return (
    <header className={`page-section-header ${bgClass}`}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Praymaker" />
            </Link>
          </div>
          <Link to={btnTopLink} className={`btn ${btnTopClass} d-none d-md-block`} role="button">
            {btnTopText}
          </Link>
          <div className="mobile-menu-open d-block d-md-none" onClick={handleOpenMobileMenu}>
            <img src={HamburgerIcon} alt="Open menu" />
          </div>
          <Collapse in={isMobileMenuOpen}>
            <div className="header-collapse">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center mobile-menu-wrapper">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logoMenuOpen && isMobileMenuOpen ? logoMenuOpen : logo}
                        alt="Praymaker"
                      />
                    </Link>
                  </div>
                  <div className="mobile-menu-close" onClick={handleCloseMobileMenu}>
                    <img src={CloseIcon} alt="Close menu" />
                  </div>
                </div>
              </div>
              <div className="container mobile-menu-content">
                <Link to={btnTopLink} className={`btn btn-block ${btnTopClass}`} role="button">
                  {btnTopText}
                </Link>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="container text-center">
        <h1 className="dot">{heading}</h1>
        <div className="row justify-content-center">{children}</div>
        <Link
          to={btnLink}
          className="btn btn-primary btn-hover-no-border btn-lg block-xs"
          role="button"
        >
          {btnText}
        </Link>
      </div>
    </header>
  );
};

export default Header;
