import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import CtaLarge from 'components/CtaLarge';
import CtaSmall from 'components/CtaSmall';
import Footer from 'components/Footer';
import Logo from 'assets/logo/logo.svg';

const TermsOfUse = () => {
  useEffect(() => {
    document.title = 'Praymaker • Terms Of Use';
  }, []);

  return (
    <>
      <header className="page-section-header simple">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Praymaker" />
            </Link>
          </div>
        </div>
      </header>

      <section className="page-section-main">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <h1>Terms Of Use</h1>

              <p className="spacing-small">Last updated: 3/8/2022</p>

              <h2>1) Introduction</h2>

              <p>
                Leigh Kim Consulting, LLC d/b/a Praymaker (“Praymaker”) provides service to you
                subject to these Terms of Use, which may be updated by Praymaker from time to time
                without notice to you. These Terms of Use constitute a binding agreement between
                Praymaker and you governing your use of Praymaker services. By using, reviewing
                and/or visiting: (i) the www.praymaker.com website to, for example, send, receive,
                or create a Prayer (defined below); (ii) any other Services or social media profiles
                owned or controlled by Praymaker; (iii) sending or receiving communications to,
                from, or on behalf of Praymaker; or (iv) otherwise interacting with services
                provided by Praymaker (collectively, the “Services”), you signify your agreement to
                both these Terms of Use and the Praymaker Privacy Policy, which is incorporated into
                these Terms of Use. You may only use the Services if you agree to abide by all
                applicable laws and to these Terms of Use.{' '}
                <strong>
                  PLEASE READ THESE TERMS OF USE CAREFULLY, INCLUDING THE BINDING ARBITRATION AND
                  CLASS ACTION WAIVER PROVISIONS LOCATED IN SECTION 9. IF YOU DO NOT AGREE TO THESE
                  TERMS OF USE, YOU MAY NOT USE THE SERVICES.
                </strong>
              </p>

              <h2>2) Conditions for Use </h2>

              <p>
                a) You may use the Services as set forth in these Terms of Use, provided that: (i)
                your use of the Services is solely for your personal, non-commercial use; (ii) you
                will not copy or distribute any part of the Services in any medium except as set
                forth herein; (iii) you will not alter or modify any part of the Services; (iv) you
                will not use the Services for any purpose prohibited by law; and (v) you will
                otherwise comply with these Terms of Use.
              </p>
              <p>
                b) You agree to provide true, accurate, current and complete information in
                connection with your use of the Services. If any information provided changes, you
                agree to update your information as soon as possible. If Praymaker suspects that
                your information is not complete, current, or accurate, or that you have otherwise
                violated these Terms of Use, your account may be subject to suspension or
                termination, and you may be barred from using the Services.
              </p>
              <p>
                c) You are responsible for keeping any registration information used in connection
                with the Services confidential, including your username and password. You agree to
                notify us as soon as possible of any unauthorized use of or access to your account
                used in connection with the Services. You agree that Praymaker is not liable for any
                damages, losses, expenses, or liabilities related to unauthorized access to or use
                of your account used in connection with the Services.
              </p>
              <p>
                d) You agree not to use or launch any automated system, including without
                limitation, “robots,” “spiders,” or similar technological devices or programs, to
                access the Services. You agree not to collect or use any personally identifiable
                information including without limitation account names, email addresses, or other
                information except as necessary for use of the Services.
              </p>

              <h2>3) Services and Payment Terms</h2>

              <p>
                a) The Services provide a platform whereby personalized prayer requests may be made
                by requestors (“Prayer Requests” and “Requestors”) and personalized prayers
                (“Prayers”) may be provided in response by spiritual advisors (“Advisors”) either
                directly to Requestors or to third party recipients designated by Requestors
                (“Recipients”). These Terms of Use apply to all users of the Services, including
                Requestors, Advisors, and Recipients.
              </p>
              <p>
                b) Requestors acknowledge that Advisors have the discretion to determine how to
                fulfill Prayer Requests and that Advisors may not precisely follow the Prayer
                Request submitted. Praymaker charges Requestors at the time a Prayer is requested.
                Requestors agree to pay all amounts due when they submit Prayer Requests, including
                any applicable service, transaction, or processing fees and comply with any
                third-party payment processor terms and conditions, if any.
              </p>
              <p>
                c) Advisors agree with respect to each Prayer they provide that: (a) the Advisor’s
                name, the Requestor’s name and the Recipient’s name, if different, will be used in
                each Prayer unless the Requestor directs otherwise; and (b) the Prayer Request for a
                Prayer will be at least generally followed; but (c) Advisors are otherwise solely
                and exclusively responsible for the manner, method, details, and means of fulfilling
                each Prayer Request and agree that Praymaker does not control those elements.
              </p>
              <p>
                d) Advisors agree that their relationship with Praymaker is not an employment
                relationship, but rather an independent contractor relationship. Advisors are not
                joint venturers, franchisees, partners, agents, or employees of Praymaker and will
                not represent themselves as such.
              </p>
              <p>
                e) Praymaker pays Advisors a percentage of the amount paid to Praymaker in
                connection with each successfully fulfilled Prayer Request, exclusive of any fees or
                taxes associated therewith.
              </p>
              <p>
                f) With respect to monies you receive from Praymaker, you agree that Praymaker is
                not responsible for any contributions, payments, taxes, or deductions for Social
                Security, retirement benefits, unemployment insurance, or any other required
                deductions, or any withholding or income taxes.
              </p>
              <p>
                g) To the extent you send or receive monies relating to the Services through a
                third-party payment provider, Praymaker does not operate, own, or control such
                payment provider and your use of such provider is governed by its terms and
                conditions, not these Terms of Use. If you fail to meet those terms and conditions,
                you may not be able to send or receive money in connection with the Services.
              </p>
              <p>
                h) Praymaker reserves the right to reject any Prayer Request at its sole discretion.
                If Praymaker rejects a Prayer Request, Praymaker will refund any amounts paid by the
                Requestor.
              </p>
              <p>i) Prayers may not be returned or exchanged.</p>
              <p>
                j) You agree that: (i) Praymaker may review all Prayer Requests and Prayers and may
                request revisions and/or additional information from Requestors and/or Advisors to
                increase the quality of the Services; (ii) the Praymaker audio introduction to each
                Prayer, if any, will remain intact and that you will not alter, modify or remove the
                introduction from any Prayer; and (iii) Praymaker may, in its sole discretion,
                refuse to honor any Prayer Request.
              </p>
              <p>
                k) In connection with any Prayer Request, Prayer or other Service, you may not
                upload, post, email or otherwise transmit any information that:
              </p>
              <ul>
                <li>
                  i) Is libelous or defamatory, pornographic, sexually explicit, unlawful,
                  plagiarized, or otherwise harmful to others;
                </li>
                <li>
                  ii) Infringes or violates any patent, copyright, trademark, trade secret or other
                  property right;
                </li>
                <li>iii) Breaches a duty of confidentiality owed to any other person;</li>
                <li>
                  iv) A reasonable person would consider harassing, abusive, threatening, harmful,
                  vulgar, profane, obscene, excessively violent, racially, ethnically or otherwise
                  objectionable or offensive in any way;
                </li>
                <li>
                  v) Constitutes a breach of any person’s privacy or publicity rights, a
                  misrepresentation of facts, or hate speech;
                </li>
                <li>
                  vi) Violates or encourages others to violate any applicable law, statute,
                  ordinance or regulation;
                </li>
                <li>vii) Solicits personal information from anyone under 18;</li>
                <li>viii) Is false, deceptive, or misleading; or</li>
                <li>
                  ix) Contains viruses, Trojan horses, worms, time bombs, cancelbots or other
                  similar harmful programming routines.
                </li>
              </ul>
              <p>
                l) To the extent any Advisor indicates that Prayer revenue will be, in whole or in
                part, donated to any charity, Praymaker does not endorse and is not affiliated with
                such charity. Praymaker further makes no representation or warranty with respect to
                such donation or charity. By using the Services, you specifically release Praymaker
                from any and all liability arising from or relating to such purported donation,
                actual donation, or charity.
              </p>
              <p>
                m) Praymaker reserves the right at any time and from time to time to modify or
                discontinue, temporarily or permanently, the Services (or any part thereof) with or
                without notice. Praymaker shall not be liable to you or to any third party for any
                modification, suspension or discontinuance of the Services.
              </p>

              <h2>4) Intellectual Property Rights</h2>

              <p>
                a) The content created by Praymaker, including without limitation, the code, text,
                graphics, and photos created by and for the Services, interactive features of the
                Services and the trademarks, service marks and logos contained therein, are owned by
                or licensed to Praymaker, subject to copyright and other intellectual property
                rights under applicable law. The Services are provided to you for your information
                and personal use only and may not be used, copied, reproduced, distributed,
                transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any
                purpose whatsoever without the prior written consent of Praymaker or as expressly
                provided herein. Praymaker reserves all rights not expressly granted in and to the
                Services.
              </p>
              <p>
                b) A Requestor who pays for and receives a Prayer is given a limited, non-exclusive,
                royalty-free, worldwide, sublicensable, revocable license to use, reproduce,
                distribute, and publicly playback the Prayer solely for personal, non-commercial
                use. A Requestor may not sell a Prayer, and may only sublicense a Prayer to the
                extent permitted by these Terms of Use by, for example, sending the Prayer to a
                Recipient, or by sharing it on a social media platform. Praymaker reserves the right
                to use any Prayer in portion or in whole in connection with the Services by, for
                example, using Prayers as examples of what the Services provide. Except as otherwise
                set forth herein, no ownership or rights in the Prayer are transferred, assigned, or
                sold to the Requestor through the purchase of the Prayer. Praymaker reserves the
                right to terminate all or part of the foregoing licenses at any time for any reason.
              </p>
              <p>
                c) Advisors have no rights whatsoever in or to any Prayers submitted to Praymaker.
              </p>
              <p>
                d) To the extent that you provide Praymaker any idea, suggestion, proposal, plan, or
                other material related to the Services, you grant to Praymaker an unlimited right to
                use and exploit such material in any manner, including commercially, and you
                renounce any right you may have in such material.
              </p>

              <h2>5) Termination</h2>

              <p>
                a) Praymaker may, without prior notice, immediately terminate your Praymaker account
                and/or access to the Services for any reason in Praymaker’s sole and unlimited
                discretion, including but not limited to:
              </p>
              <ul>
                <li>
                  i) Breaches or violations of these Terms of Use or other incorporated agreements
                  or guidelines;{' '}
                </li>
                <li>ii) Requests by law enforcement or other government agencies; </li>
                <li>iii) A request by you to delete your account; </li>
                <li>iv) Discontinuance or modification of the Services; </li>
                <li>v) Unexpected technical or security issues or problems; </li>
                <li>vi) Extended periods of inactivity; </li>
                <li>vii) Engagement by you in fraudulent or illegal activities; and/or </li>
                <li>viii) Nonpayment of any fees owed by you in connection with the Services.</li>
              </ul>
              <p>b) Termination of your Praymaker account may include:</p>
              <ul>
                <li>i) Removal of access to the Services; </li>
                <li>
                  ii) Deletion of your account information and any related information, files and
                  materials, including any information associated with Prayer Requests and Prayers;
                  and
                </li>
                <li>iii) Barring of further use of the Services. </li>
              </ul>
              <p>
                c) All terminations will be made in Praymaker’s sole discretion and Praymaker shall
                not be liable to you or any third party for any such termination.
              </p>

              <h2>6) Indemnification</h2>

              <p>
                You agree to defend, indemnify and hold harmless Praymaker, and its parents,
                subsidiaries, affiliates, members, directors, officers, employees, shareholders,
                partners, licensors, distributors, vendors, suppliers, agents, resellers, owners,
                and operators (the “Praymaker Parties”), from and against any and all claims,
                damages, obligations, losses, liabilities, costs, debts, and expenses (including but
                not limited to attorney’s fees) arising from: (i) your use of and access to the
                Services; (ii) these Terms of Use; (iii) your violation of any third party right,
                including without limitation any copyright, property, or privacy right; or (iv) any
                claim that you and/or Praymaker caused damage to a third party, including but not
                limited to any Recipient, through the use of the Services. This defense and
                indemnification obligation survives your use of the Services. You agree that
                Praymaker may select its own counsel and control the defense of any claim
                indemnified by you and you agree to cooperate with Praymaker in connection with any
                such claim.
              </p>

              <h2>7) Warranty Disclaimer</h2>

              <p>
                a) YOU AGREE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR OWN RISK AND THAT YOU
                WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES, LOSSES, OR LIABILITIES ARISING FROM OR
                IN CONNECTION WITH THE USE OF THE SERVICES. THE SERVICES ARE PROVIDED ON AN “AS IS”
                AND “AS AVAILABLE” BASIS.
              </p>
              <p>
                b) TO THE FULLEST EXTENT PERMITTED BY LAW, THE PRAYMAKER PARTIES DISCLAIM ALL
                WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, IN CONNECTION WITH
                THE SERVICES AND YOUR USE THEREOF, INCLUDING WARRANTIES RELATED TO MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND WARRANTIES ARISING OUT OF
                COURSE OF DEALING OR USAGE OR TRADE.
              </p>
              <p>
                c) THE PRAYMAKER PARTIES MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                COMPLETENESS OF THE CONTENT OF THE SERVICES OR THE CONTENT OF ANY SOCIAL MEDIA
                PLATFORM OR THIRD-PARTY SITE LINKED TO THE SERVICES. YOU AGREE THAT THE PRAYMAKER
                PARTIES WILL HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
                INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OR ANY HARM OF ANY
                NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (III)
                UNAUTHORIZED ACCESS TO OR USE OF THE PRAYMAKER PARTIES’ SERVERS AND/OR ANY AND ALL
                PERSONAL INFORMATION STORED THEREIN, (IV) INTERRUPTION OR CESSATION OF TRANSMISSION
                TO OR FROM THE SERVICES, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
                BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (VI) ANY LOSS
                OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY INFORMATION POSTED,
                EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
              </p>
              <p>
                d) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
                PRAYMAKER PARTIES OR OTHERWISE IN CONNECTION WITH THE SERVICES SHALL CREATE ANY
                WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF USE. AS WITH THE PURCHASE OF A
                PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
                BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
              </p>

              <h2>8) Limitations of Liability</h2>

              <p>
                a) IN NO EVENT SHALL THE PRAYMAKER PARTIES BE LIABLE TO YOU OR TO ANY RECIPIENT OR
                OTHER THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
                DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES IN THE
                SERVICES OR PRAYER REQUESTS, OR PRAYERS, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
                ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (III)
                UNAUTHORIZED ACCESS TO OR USE OF PRAYMAKER’S SECURE SERVERS AND/OR ANY AND ALL
                PERSONAL INFORMATION STORED THEREIN, (IV) INTERRUPTION OR CESSATION OF TRANSMISSION
                TO OR FROM OUR SERVICES, (IV) BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY
                BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY, AND/OR (V) LOSS OR
                DAMAGE OF ANY KIND CAUSED BY ANY PRAYER POSTED, EMAILED, TRANSMITTED, OR OTHERWISE
                MADE AVAILABLE VIA THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY
                OTHER LEGAL THEORY, AND WHETHER OR NOT THE PRAYMAKER PARTIES ARE ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO
                THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU AGREE THAT
                THIS LIMITATION OF LIABILITY IS A FUNDAMENTAL ELEMENT OF THE AGREEMENT BETWEEN YOU
                AND THE PRAYMAKER PARTIES.
              </p>
              <p>
                b) TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION, THE
                PRAYMAKER PARTIES’ TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY, INCLUDING
                ANY RECIPIENT, UNDER ANY AND ALL CAUSES OF ACTION OR THEORIES OF LIABILITY IS
                LIMITED TO THE FEES RECEIVED BY THE PRAYMAKER PARTIES IN CONNECTION WITH ALL PRAYER
                REQUESTS OR PRAYERS SUBMITTED BY YOU TO THE PRAYMAKER PARTIES DURING THE 12 MONTHS
                PRECEDING THE CLAIM PURPORTEDLY GIVING RISE TO SUCH LIABILITY.
              </p>
              <p>
                c) SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OR LIMITATION OF REMEDIES. IF SUCH
                LAWS APPLY TO YOU, YOU MAY HAVE ADDITIONAL RIGHTS AND THE FOREGOING EXCLUSIONS AND
                LIMITATIONS MAY NOT APPLY TO YOU.
              </p>
              <p>
                d) The Services are controlled and offered by Praymaker from its facilities in the
                United States of America. Praymaker makes no representations that the Services are
                appropriate or available for use in other locations. Those who access or use the
                Services from other jurisdictions do so of their own volition and are responsible
                for compliance with local law.
              </p>

              <h2>9) Arbitration Agreement and Waiver of Rights</h2>

              <p>
                a){' '}
                <strong>
                  THIS SECTION LIMITS YOUR RIGHT TO MAINTAIN COURT ACTIONS, YOUR RIGHT TO A JURY
                  TRIAL, AND YOUR RIGHT TO PARTICIPATE IN CLASS OR REPRESENTATIVE ACTIONS, AMONG
                  OTHER RIGHTS AND REMEDIES. PLEASE READ IT CAREFULLY.
                </strong>
              </p>
              <p>
                b) Except as otherwise provided herein, any dispute, claim or controversy arising
                out of or relating to the Services, or the relationship between you and the
                Praymaker Parties, including the breach, termination, enforcement, interpretation or
                validity of these Terms of Use, including the determination of the scope or
                applicability of this agreement to arbitrate (collectively, “Claims”), shall be
                finally settled by binding alternative dispute resolution through the New Era ADR
                platform by one professional neutral with substantial experience in resolving
                commercial or consumer disputes, as applicable. This neutral shall be randomly
                selected from the appropriate list of New Era ADR neutrals in accordance with the
                rules and procedures of New Era ADR. The prevailing party may be entitled to receive
                reimbursement of its reasonable costs and expenses (including reasonable attorneys’
                fees and all other expenses) incurred in connection therewith, at the neutral’s
                discretion. The award entered by the arbitrator shall be final and binding upon the
                parties. Judgment on the award entered by the arbitrator shall be entered in the
                federal or state courts located in Chicago, Illinois. This clause shall not preclude
                parties from seeking provisional remedies in aid of arbitration, which may only be
                sought in the federal or state courts located in Chicago, Illinois. You submit to
                the jurisdiction of the federal or state courts located in Chicago, Illinois and
                waive any objection to the inconvenience of such forum.
              </p>
              <p>
                c) You and Praymaker, and any other parties, shall maintain the confidential nature
                of the arbitration proceeding, including the award entered by the arbitrator, except
                as may be necessary to prepare for or conduct the arbitration, or except as may be
                necessary in connection with a court application for a preliminary remedy, a
                judicial challenge to an award or its enforcement, or unless otherwise required by
                law or judicial decision.
              </p>
              <p>
                d) Any cause of action arising out of or related to the Services must commence
                within one (1) year after the cause of action accrues. Otherwise, such cause of
                action is permanently barred.
              </p>
              <p>
                e) You and the Praymaker Parties agree that with respect to any Claim, neither may:
                (i) act as a class representative or private attorney general, or (ii) participate
                as a member of a class of claimants. No Claim may be arbitrated or litigated on a
                class or representative basis.
              </p>
              <p>
                f) If any provision of this Section is found to be invalid or unenforceable, such
                provision will be, if possible, deemed modified to conform as much as possible to
                the intent of the parties and, if not, shall be severed and the remainder of this
                Section will continue in full force and effect.
              </p>
              <p>
                g) Any waiver of any portion or all of this Section must be recorded in a writing
                signed by the waiving party, and any such waiver will have no effect on the
                enforceability of the remainder of these Terms of Use.
              </p>
              <p>
                h) This Section survives the termination of the relationship between you and the
                Praymaker Parties.
              </p>
              <p>
                i) You have the right to opt out of the arbitration agreement in this Section by
                sending, within 30 days after becoming subject thereto, written notice of your
                decision to opt out to: <a href="mailto:info@praymaker.com">info@praymaker.com</a>{' '}
                and/or to Praymaker, c/o Mercer Oak LLC, 29 N. Ada St., Chicago, IL 60607. Your
                notice must include your name and address, any usernames or email addresses you have
                used to in connection with the Services, and a statement indicating your desire to
                opt out of the arbitration agreement. The remainder of these Terms of Use will
                continue to apply to you even if you opt out. If you opt out, you nonetheless agree
                that Claims may only brought in the federal or state courts located in Chicago,
                Illinois, you submit to the jurisdiction of such courts, and waive any objection to
                the inconvenience of such fora.
              </p>

              <h2>10) Ability to Enter Binding Contract</h2>

              <p>
                You affirm that you are more than 18 years of age and are fully able and competent
                to enter into the terms, conditions, obligations, affirmations, representations, and
                warranties set forth in these Terms of Use, and to abide by and comply with these
                Terms of Use. The Services are not intended for children under 18. If you are under
                18 years of age, then you may not use the Services.
              </p>

              <h2>11) Assignment</h2>

              <p>
                Except as otherwise set forth herein, these Terms of Use, and any rights and
                licenses granted hereunder, may not be assigned, transferred, delegated, or
                sublicensed by you, but may be assigned, transferred, delegated, and sublicensed by
                Praymaker without restriction.
              </p>

              <h2>12) Import/Export Restrictions</h2>

              <p>
                You may not use, export, import, or transfer any part of the Services except as
                authorized by U.S. law, the laws of the jurisdiction in which you use or access our
                Site, or any other applicable laws. In particular, but without limitation, no part
                of our Site may be exported or re-exported: (a) into any country subject to embargo
                by the U.S.; or (b) to anyone on the U.S. Treasury Department’s list of Specially
                Designated Nationals or the U.S. Department of Commerce’s Denied Persons List or
                Entity List. By using the Services, you represent and warrant that: (a) you are not
                located in a country subject to embargo by the U.S. or designated as a “terrorist
                supporting” country by the U.S.; and (b) you are not listed on any U.S. Government
                list of prohibited or restricted parties. You agree that the Services are subject to
                the export control laws and regulations of the U.S. You will comply with those laws
                and regulations and will not, without prior U.S. government authorization, export,
                re-export, or transfer the Services, either directly or indirectly, to any country
                in violation of those laws and regulations.
              </p>

              <h2>13) Miscellaneous</h2>

              <p>
                a) If any provision of these Terms of Use is deemed invalid by a court of competent
                jurisdiction, the invalidity of such provision shall not affect the validity of the
                remaining provisions of these Terms of Use, which shall remain in full force and
                effect.
              </p>
              <p>
                b) No waiver of any term of this these Terms of Use shall be deemed a further or
                continuing waiver of such term or any other term, and Praymaker’s failure to assert
                any right or provision under these Terms of Use shall not constitute a waiver of
                such right or provision.
              </p>
              <p>
                c) The Praymaker Parties shall not be liable for any delay or failure in performance
                due in whole or in part to any acts of God (such as earthquakes, storms, floods,
                etc.), unavoidable accidents, laws, rules, regulations or orders of government
                authorities, acts of war (declared or not), terrorism, hostilities, blockades, civil
                disturbances, viruses or pandemics, embargoes, strikes, or any other event or cause
                beyond the reasonable control of any Praymaker Party.
              </p>
              <p>
                d) These Terms of Use are governed by and construed in accordance with the laws of
                the State of Illinois, without giving effect to any conflict of laws rules or
                provisions.
              </p>
              <p>
                e) Praymaker reserves the right to amend these Terms of Use at any time in its sole
                discretion with or without prior notice, which shall take effect upon posting to the
                Services. It is your responsibility to review these Terms of Use for any changes.
                Your use of the Services following any amendment of these Terms of Use will signify
                your assent to and acceptance of its revised terms.
              </p>

              <h2>14) Violations of Terms of Use</h2>

              <p>
                Please report any violations of the Terms of Use, including objectionable user
                submitted content or behavior to{' '}
                <a href="mailto:info@Praymaker.com">info@Praymaker.com</a>. State the reasons for
                your concern and provide a description of the violation. Upon receiving such a
                submission, Praymaker in its sole discretion may investigate the matter and take
                such action as Praymaker determines to be appropriate.
              </p>
            </div>
          </div>
        </div>
      </section>

      <CtaLarge
        bgClass="candles-bg"
        btnLink="/request-form"
        btnText="Request a Prayer"
        text="Personal prayer for your loved ones"
      />

      <CtaSmall />

      <Footer />
    </>
  );
};

export default TermsOfUse;
